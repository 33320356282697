.product-block{
  display: flex;
  position: relative;
  font-weight: 400;
  @include respond-property(padding-bottom, 125px, 83px,74px,54px,78px);

  @include respond-down(S){
    flex-wrap: wrap;
  }

  .content{
    @include respond-property(flex, 0 0 39%, 0 0 371px,0 0 332px, 0 0 100%, 0 0 100%);
    @include respond-property(margin-top, 40px, -20px, -18px, 0, 0);


    .wrapper-product-name{
      display: flex;
      align-items: center;
      margin-bottom: 45px;

      @include respond(XS){
        margin-bottom: 45px;
      }

      .parent-full-product-name{
        @include respond-property(flex, 0 0 35%, 0 0 172px, 1 1 auto, 1 1 auto, 1 1 auto);
        position: relative;
        height: 22px;

        @include respond(XS){
          height: 50px;
        }

        .full-product-name{
          @include respond-property(font-size, 33px, 21px, 27px, 21px, 18px);
          top: -10px;
          opacity: 0;
          position: absolute;
          transition: opacity 0.4s, top 0.4s ;
          transition-delay: 0.0s;
          font-weight: 600;
          text-align: center;
          z-index: 8;

          .sub {
            color: #666;
            display: block;
          }

          @include respond(XS){
            width: 230px;
            transform: translateX(-50%);
            left: 50%;
          }
          
          &.current{
            top: 0;
            opacity: 1;
            z-index: 10;
          }
        }
      }

      .wrapper-arrow{
        display: flex;
        justify-content: space-between;
        @include respond-property(flex, 0 0 120px, 0 0 88px,0 0 88px, 0 0 105px, 0 0 0px);
        @include respond(XS){
          .slider-array{
            position: absolute;
            top: 78px;
            z-index: 20;

            &.left{
              left: 0;
            }
            &.right{
              right: 0;
            }
          }
        }

      }
    }

    .parent-short-name{
      position: relative;
      @include respond-property(margin-bottom, 17px, 7px, 12px, 12px, 12px);
      @include respond-property(height, 25px, 20px, 20px, 20px, 22px);


      .short-name{
        letter-spacing: 0.1em;
        font-weight: 600;
        position: absolute;
        z-index: 8;
        top: -10px;
        opacity: 0;
        display: block;
        @include respond-property(font-size, 22px, 16px, 16px, 16px, 18px);
        color: $col_blue_03;
        transition: opacity 0.2s, top 0.3s ;

        &.current{
          top: 0;
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .parent-subtitle {
      position: relative;
      @include respond-property(margin-bottom, 5px, 0px, 3px, 13px, 12px);
      @include respond-property(height, 52px, 43px, 43px, 47px, 12px);

      @include respond-up(M){
        margin-right: -80px;
      }

      .subtitle {
        position: absolute;
        top: -10px;
        opacity: 0;
        z-index: 8;
        font-weight: 400;
        display: block;
        transition: opacity 0.2s, top 0.3s ;
        transition-delay: 0.1s;
        color: #444;

        @include respond-property(font-size, 28px, 20px, 20px, 18px, 18px);

        @include respond-down(S){
          max-width: 447px;
        }

        &.current{
          top: 0;
          opacity: 1;
          z-index: 10;
        }
      }

    }

    .parent-title{
      position: relative;
      @include respond-property(margin-bottom, 5px, 0px, 3px, 13px, 12px);
      @include respond-property(height, 112px, 83px, 76px, 60px, 12px);

      @include respond-up(M){
        margin-right: -80px;
      }


      .title{
        position: absolute;
        top: -10px;
        opacity: 0;
        z-index: 8;
        font-weight: 400;
        display: block;
        transition: opacity 0.2s, top 0.3s ;
        transition-delay: 0.1s;
        margin-bottom: 20px;

        @include respond-property(font-size, 40px, 28px, 24px, 24px, 24px);

        @include respond-down(S){
          max-width: 447px;
        }

        &.current{
          top: 0;
          opacity: 1;
          z-index: 10;
        }
      }

    }

    .parent-text{
      position: relative;
      @include respond-property(margin-bottom, 28px, 10px, 10px, 10px, 10px);
      @include respond-property(height, 190px, 200px, 200px, 160px, 12px);

      @include respond-up(M){
        margin-right: -30px;
      }

      .list-type-dot {
        padding: 10px 0;

        .item {
          line-height: 19px;
          margin-bottom: 10px;

          &:before {
            top: 5px
          }

          @include respond-property(font-size, 19px, 14px, 14px, 16px, 16px);

          @include respond-down(L){
            color: $col_text_01;
          }
        }
      }

      .text{
        @include respond-property(font-size, 19px, 14px, 14px, 16px, 16px);
        @include respond-property(color, $col_text_02, $col_text_01, $col_text_01, $col_text_01, $col_text_01);

        position: absolute;
        top: -10px;
        opacity: 0;
        display: block;
        transition: opacity 0.2s, top 0.3s ;
        transition-delay: 0.2s;
        line-height: 1.65;
        z-index: 8;

        &.current{
          top: 0;
          opacity: 1;
          z-index: 10;
        }
      }


    }

    .wrapper-buttons{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrapper-arrow{
        display: flex;
        justify-content: space-between;
        @include respond-property(flex, 0 0 120px, 0 0 88px,0 0 88px);


      }

      @include respond(XS){
        justify-content: center;
      }
    }

    .wrapper-button-slider{
      position: relative;
      @include respond-property(width,173px,150px,144px, 175px,175px);
      @include respond-property(height,61px,44px,44px,52px, 42px);

      .button{
        //background: transparent;
        //border: 1px solid $col_blue_01;
        //color: $col_blue_01;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
        opacity: 0;

        &.current{
          opacity: 1;
          z-index: 10;
        }
      }
    }

    @include respond-down(S){
      order: 2;
    }
  }

  .content-photo{
    @include respond-property(flex, 0 0 61%, 1 1 auto, 1 1 auto, 0 0 100%, 0 0 100%);
    @include respond-property(padding-left, 100px,  80px, 28px, 0, 0);
    @include respond-property(display, block, block, flex, flex, flex);
    align-items: center;

    @include respond-down(S){
      margin-bottom: 76px;
    }
    @include respond-down(XS){
      margin-bottom: 17px;
    }


    .main-photo{
      display: flex;
      align-items: center;
      @include respond-property(margin-bottom, 50px, 40px, 0, 0, 0);

      @include respond-down(M){
        flex: 1 1 auto;
      }

      .wrapper-photo{
        @include respond-property(flex, 0 0 65%, 0 0 300px,1 1 auto,1 1 auto,1 1 auto);
        position: relative;



        img{
          display: none;
        }

        .parent-photo{
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          left: 50%;
          bottom: 0;
          width: 360px;
          @include respond-property(width,360px, 260px, 204px, 283px, 186px);

          .photo{
            background: {
              image: url("/static/images/delete/product.png");
              repeat: no-repeat;
              position: center;
              size: contain;
            };

            transform: scale(0.8);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: absolute;
            opacity: 0;
            transition: $default-transition;
            z-index: 8;

            &.current{
              z-index: 10;
              opacity: 1;
              transform: scale(1);
            }
          }

        }

        .circle{
          margin: 0 auto;
          display: block;
          border-radius: 1000px;
          @include respond-property(width,325px, 231px,204px,283px,186px);
          @include respond-property(height,325px, 231px,204px,283px,186px);
          background-color: $col_grey_02;
        }

      }

      .parent-full-product-name{
        @include respond-property(flex, 0 0 35%, 0 1 300px);
        position: relative;
        height: 85px;
        @include respond-property(height, 85px, 55px,0, 0, 0);
        margin-left: 30px;

        .full-product-name{
          font-size: 33px;
          @include respond-property(font-size, 33px, 21px, 27px, 27px, 27px);
          top: -10px;
          opacity: 0;
          position: absolute;
          transition: opacity 0.2s, top 0.3s ;
          transition-delay: 0.25s;
          z-index: 8;

          .sub {
            color: #666;
            display: block;
          }


          &.current{
            z-index: 10;
            top: 0;
            opacity: 1;
          }
        }
      }
    }

    .wrapper-preview{
      display: flex;
      justify-content: space-between;
      @include respond-property(flex-direction, row, row, column,column);

      @include respond-down(M){
        flex: 0 0 120px;
      }

      .preview{
        cursor: pointer;

        @include respond-property(flex, 0 0 145px, 0 0 100px);

        @include respond-down(M){
          margin-bottom: 25px;
        }

        .link{
          @include respond-property(height,145px, 100px,auto,auto,auto);
          position: relative;
          display: block;

          .photo{
            position: relative;
            height: 100%;
            width: 100%;
            display: block;
            z-index: 2;
            background: {
              repeat: no-repeat;
              size: contain;
              position: center;

            };

            transition: transform 0.2s, filter 0.4s;
            transform: scale(0.7);
            filter: grayscale(100%);

          }

          .short-name{
            flex: 1 1 auto;
            margin-left: 7px;
            color: $col_text_01;
            font-weight: 600;
            font-size: 16px;
            transition: $default-transition;

            &:hover{
              color:  $col_blue_01;
            }
          }

          .circle{
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;

            border-radius: 1000px;
            background-color: $col_grey_02;
            display: block;
            transition: transform 0.4s;
            transform: scale(0.5);
            opacity: 0;
            z-index: 1;

            svg{
              @include respond-property(display, none, none, block);
              fill: none;
              stroke-width: 2px;
              stroke: $col_blue_01;
            }

            @include respond-down(M){
              position: static;
              background-color: transparent;
              border: 2px solid $col_blue_01;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 22px;
              height: 22px;



              svg{
                width: 6px;

              }
            }
          }

          &:hover{
            .photo{
              filter: grayscale(0%);
            }

            .circle{
              opacity: 1;
              transform: scale(1);
            }

          }

          @include respond-down(M){
            display: flex;
            justify-content: space-around;
          }

        }

        &.current{
          .link{
            .photo{
              filter: grayscale(0%);
              transform: scale(1);
            }

            .circle{
              opacity: 1;
              transform: scale(1);
            }

            .short-name{
              color:  $col_blue_01;
            }
          }
        }
      }
    }


  }

}