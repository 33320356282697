html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  font-size: $body-font-size;
  font-family: $font;
  line-height: 1.3;
  font-weight: 300;
  color: $col_text_01;
  a {
    text-decoration: none;
  }

  -webkit-text-size-adjust: none;

  a, label, button{
    -webkit-tap-highlight-color: transparent;
  }
}

sup {
  vertical-align: super;
  font-size: smaller;
}
sub {
  vertical-align: sub;
  font-size: smaller;
}

.cookie-block {
  display: none;
  position: fixed;
  z-index: 300;
  right: 0;
  left: 0;
  bottom: 0;
  background: $col_blue_01;
  padding: 25px 0;

  @include respond-down(L){
    padding: 22px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-size: 19px;
    line-height: 1.6em;

    @include respond-down(L){
      font-size: 14px;
    }
    @include respond-down(S){
      flex-wrap: wrap;
    }

  }

  .accept {
    padding-right: 50px;

    @include respond-down(S){
      padding-right: 0px;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }

  .button {
    user-select: none;
    flex: 0 0 206px;
    text-align: center;
    color: $col_link_03;
    background: #fff;

    @include respond-down(L){
      flex: 0 0 138px;
    }
    @include respond-down(S){
      flex: 0 0 152px;
    }

    &:hover {
      background: rgba(255,255,255,.8);
    }
  }
}

.we-can-block {
  visibility: hidden;

  @include respond-down(S){
    .background {
      z-index: 400;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(255,255,255,0.75);
      opacity: 0;
      transition: opacity 0.25s linear;
    }
  }

  .block-wrapper {
    z-index: 500;
    position: fixed;
    right: -10px;
    top: 50%;
    margin-top: -175px;
    transition: opacity 0.45s linear, transform 0.55s cubic-bezier(0.04, 0.5, 0, 1);
    transform: translateX(300px);

    @include respond-down(S){
      width: 264px;
      height: 330px;
      right: unset;
      left: 50%;
      margin-left: -132px;
      transform: translateX(0) scale(0.7);
      opacity: 0;
    }

    .blue {
      width: 242px;
      height: 324px;
      position: relative;
      z-index: 50;
      display: flex;
      align-items: center;

      @include respond-down(S){
        width: 100%;
        height: 100%;
      }

      & > svg.desktop {
        z-index: 15;
        position: absolute;
        left: -64px;
        width: 372px;
        top: -53px;

        @include respond-down(S){
          display: none;
        }
      }

      & > svg.mobile {
        display: none;
        z-index: 15;
        position: absolute;
        left: -64px;
        width: 392px;
        top: -53px;

        @include respond-down(S){
          display: block;
        }
      }

      .text {
        color: #fff;
        padding: 0 46px;
        position: relative;
        z-index: 20;
        text-align: center;

        strong {
          font-weight: 500;
        }

        @include respond-down(S){
          text-align: center;
          padding: 0 35px;
        }

        .title {
          font-size: 28px;
        }

        .accept {
          padding: 14px 0 0 0;
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 1.6em;
        }

        .inverted {
          height: 42px;
          padding: 0 26px;
          background: #fff;
          color: $col_link_03;
          font-size: 15px;
        }
      }

      .cog {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        display: flex;
        align-content: center;
        justify-content: space-around;
        position: absolute;
        top: 128px;
        left: -30px;

        @include respond-down(S){
          top: -28px;
          left: 102px;
        }

        svg {
          width: 38px;
          transform: rotate(-360deg);
          transition: transform 0.65s cubic-bezier(0.04, 0.5, 0, 1);
          transition-delay: 0.25s;

          .figure {
            fill: #0070fa;
          }
        }
      }
    }

    .white {
      z-index: 30;
      position: absolute;
      left: -40px;
      height: 115px;
      top: 100px;
      background: #fff;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0 12px 43px rgba(0,0,0,0.16);

      @include respond-down(S){
        width: 70%;
        left: 15%;
        top: -40px;
        bottom: -40px;
        height: auto;
      }
    }
  }

  &.visible {
    visibility: visible;

    .background {
      opacity: 1;
    }

    .block-wrapper {
      transform: translateX(0);

      @include respond-down(S){
        transform: scale(1);
        opacity: 1;
      }

      .cog svg{
        transform: rotate(0);
      }

    }
  }
}


.head-block{
  display: flex;
  justify-content: center;
  @include respond-property(margin-bottom, 78px, 56px,62px, 47px, 27px);

  @include respond(XS){
    //justify-content: flex-start;
  }

  h2.title-block{
    @include respond-property(font-size, 64px, 46px,40px, 40px, 28px);
    font-weight: 300;
  }
}

.container {
  padding: 0 5%;

  @include respond-down(L){
    padding: 0 5.6%;
  }
  @include respond-down(M){
    padding: 0 7.4%;
  }
  @include respond-down(S){
    padding: 0 10%;
  }
  @include respond(XS){
    padding: 0 7%;
  }
}

[data-lazy] {
  filter: blur(0px);

  &.blur-animate {
    transition: filter 0.5s linear !important;
  }

  &.blur {
    // REMOVE ANIMATION
    // filter: blur(10px) !important;
  }
}

[data-dots-background] {
  background-image: radial-gradient(#F9F9F9 20%, transparent 20%);
  background-size: 10px 10px;
  background-position: left top;
  position: relative;
  overflow: hidden;

  & > * {
    position: relative;
    z-index: 10;
  }

  & > i {
    position: absolute;
    z-index: 5;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background: $col_blue_01;
    left: 20px;
    top: 20px;
    opacity: 0;
    transition: opacity 3.0s linear;

    &.v {
      opacity: 0.2;
      transition: opacity 0.1s linear;
    }
    &.vv {
      opacity: 0.8;
      transition: opacity 2s linear;
    }
  }
}

body.v-disable {
  [data-dots-background] {
    & > i.v {
      opacity: 0;
    }
  }
}


a.active.focus,
a.active:focus,
a.focus,
a:active.focus,
a:active:focus,
a:focus,
button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus{
  outline: 0!important;
  outline-color: transparent!important;
  outline-width: 0!important;
  outline-style: none!important;
  box-shadow: 0 0 0 0 rgba(0,123,255,0)!important;
}

[data-debug="debug"] #DEBUG {
  position: fixed;
  padding: 5px;
  opacity: 0.5;
  background: #f00;
  color: #fff;
  border-radius: 5px;
  left: 10px;
  bottom: 10px;
  z-index: 99999;
  &:after {
    content: 'XL';
    @include respond-down(L)  { content: 'L';  }
    @include respond-down(M)  { content: 'M';  }
    @include respond-down(S)  { content: 'S';  }
    @include respond-down(XS) { content: 'XS'; }
  }
}
