.product-preview-list{

  @include respond-property(padding-top, 101px,63px,56px,56px,43px);
  @include respond-property(padding-bottom, 101px,63px,56px,56px,43px);

  background: $col_nude_01;
  font-weight: 400;


  .head-block{
    position: relative;
    .background{
      position: absolute;
      @include respond-property(top,-40px,-30px,-11px,16px);
      left: 0;
      right: 0;

      svg{
        .cls-1{
          stroke: $col_blue_01;
          fill: none;
        }
      }
    }

  }


  .inner-block{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .product-preview{
      cursor: pointer;
      position: relative;
      background: white;
      border-radius: 10px;
      @include respond-property(flex,0 0 18%, 0 0 18%, 0 0 31%, 0 0 48%, 0 0 100%);//!!

      @include respond-down(M){
        margin-bottom: 19px;
      }

      @include respond-down(S){
        margin-bottom: 27px;
      }

      .product, .product-hover{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        @include respond-property(padding-top,65px, 48px, 51px, 33px, 0px);
        @include respond-property(padding-bottom,48px, 34px, 30px, 34px, 30px);

        .wrapper-logo{
          flex: 0 0 100%;

          @include respond-property(padding-left, 35px, 32px, 51px, 51px, 51px);//!!
          @include respond-property(margin-bottom, 32px, 24px, 51px, 51px, 51px);//!!
          @include respond-property(height, 32px, 24px, 51px, 51px, 51px);//!!

          .logo{
            height: 100%;
            width: 100px;
              svg{
                height: 100%;
              }

            //display: block;
            //transition-delay: 0.5s;
            //transition: width 2s;


          }
        }

        .image{
          flex: 0 0 100%;
          display: flex;
          position: relative;
          margin-bottom: 24px;
          justify-content: center;
          overflow: hidden;
          @include respond-property(height, 151px, 112px, 112px, 132px, 100px);


          .circle{
            top: 0;
            position: absolute;
            background: #EFEFEF;
            border-radius: 1000px;
            @include respond-property(width, 151px, 112px, 112px, 132px, 202px);//ok
            @include respond-property(height, 151px, 112px, 112px, 132px, 202px);//ok

            @include respond(XS){
              top: -100px;
              background:  $col_nude_01;
            }
          }

          .img{
            @include respond-property(height, 151px, 112px, 112px, 132px, 112px);//!!
            @include respond-property(width, 170px, 126px, 112px, 160px, 160px);//ok
            top: 0;
            position: absolute;
            background: {
              repeat: no-repeat;
              image: url("/static/images/delete/product.png");
              position: center;
              size: contain;
            };

            @include respond(XS){
              top: -9px;
            }
          }



          img{
            display: none;
          }



        }

        .short-name{
          flex: 0 0 100%;
          display: flex;
          justify-content: center;

          @include respond-property(margin-bottom,24px, 23px, 24px, 18px, 13px);

          .name{
            font-weight: 600;
            letter-spacing: 0.2em;
            @include respond-property(font-size,22px, 16px, 16px, 18px, 16px);
          }
        }
      }

      .product-hover{
        box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.22);
        border-radius: 10px;
        position: absolute;
        //display: none;
        opacity: 0;
        z-index: 1;

        background: white;
        bottom: 0;
        width: 100%;
        left: 0;
        transition: opacity 0.5s;

        @include respond-property(padding-top,32px, 24px, 51px, 51px, 51px);//!!

        .image{
          .circle{
            opacity: 0;
            background: $col_blue_01;
            transition: opacity 0.5s;
          }
        }

        @include respond(M){
          display: none;
        }

      }

      &:hover{
        .product-hover{
          z-index: 3;
          //display: flex;
          opacity: 1;

          .image{
            .circle{
              opacity: 1;
            }
          }


          @include respond-down(M){
            display: none;
          }
        }



      }
    }

    [data-slick-bottom-arrows] {
      margin-top: -15px;
      display: flex;
      justify-content: space-between;
      width: 75px;

      .slick-arrow {
        background: #ffffff;
      }
    }

    .text-list{

      @include respond-property(flex, 0 0 100%, 0 0 100%, 0 0 31%, 0 0 48%, 0 0 100%);
      @include respond-property(margin-top, 150px, 70px, 0, 0, 0); //!!
      @include respond-property(flex-direction, row, row, column, column, row); //ok

      display: flex;
      justify-content: space-between;

      .item{
        .title{
          display: block;
          @include respond-property(font-size, 22px, 16px, 16px, 18px, 16px);
          @include respond-property(margin-bottom, 30px, 18px, 13px, 13px, 13px);
        }

        .text{
          display: block;
          max-width: 301px;
          font-size: 19px;
          color: $col_text_02;
          line-height: 1.5;
          @include respond-down(L){color: $col_text_01;}
          @include respond-property(font-size, 19px, 14px, 14px, 14px, 14px);
          @include respond-property(max-width, 301px, 216px, 238px, 238px, 238px);
        }

        @include respond-down(M){
          margin-bottom: 19px;
          &.last{
            display: none;
          }
        }

        @include respond(S){
          margin-bottom: 27px;
        }

        @include respond(XS){
          &.last{
            display: flex;
          }
        }
      }

      @include respond(XS){
        margin: 0 -7.9% 0 -7.9%;
        width: 115.8%;
        height: 135px;
        position: relative;


        &:after{
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 129px;
          background: linear-gradient(91deg, rgba(249,249,249,0.15) 0%, rgba(249,249,249,0.46) 30%, rgba(249,249,249,0.7) 53%, rgba(249,249,249,0.7) 100%); /* w3c */
          z-index: 20;
          width: 20%;
        }

        .slick-arrow {
          z-index: 20;
          bottom: 0;
          position: absolute;
          text-indent: -9999px;
          overflow: hidden;
          display: block;
          transition: background-color 0.2s, background-image 0.2s;
          background-color: transparent;
          border: 1px solid $col_grey_01;

          justify-content: center;
          align-items: center;
          cursor: pointer;
          @include respond-property(width, 56px, 41px,41px,47px, 35px);
          @include respond-property(height, 47px, 34px,35px,40px, 30px);

          background: {
            repeat: no-repeat;
            position: center;
            size: 5px 10px;
          };


          &.active{
            border: 1px solid $col_blue_01;
            background-color: $col_blue_01;
          }
        }

        .item{
          flex: 0 0 220px;
          width: 220px;
          max-width: 200px;

          &:first-child{
            padding-left: 14%;
          }
        }

      }


    }

  }
}