.static-page {
  background: #F9F9F9;

  .page-container {
    background: #FFF;
    border-radius: 10px;
    margin-bottom: 100px;
    min-height: 80vh;
    padding: 40px 15%;

    @include respond-down(M){
      padding: 40px 7% 80px 7%;
    }
    @include respond-down(S){
      padding: 40px 4% 80px 4%;
    }
    @include respond-down(XS){
      padding: 20px;
    }

    .indent {
      clear: both;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      background: transparent;
      border: none;

      &.sm {
        height: 15px;
      }
      &.md {
        height: 25px;
      }
    }

    .text-h1 {
      font-size: 50px;
      line-height: 65px;
      text-align: center;
      margin-bottom: 30px;

      @include respond-down(M){
        font-size: 30px;
        line-height: 40px;
      }
      @include respond-down(S){
        font-size: 24px;
        line-height: 30px;
      }
      @include respond-down(XS){
        text-align: left;
      }
    }

    .text {
      font-weight: 400;
    }

    ul.text {
      li {
        padding-left: 30px;
        position: relative;
        margin-bottom: 7px;

        &:after {
          content: '';
          width: 20px;
          height: 1px;
          background: rgba(0,0,0,0.5);
          position: absolute;
          left: 0;
          top: 12px;
        }
      }
    }
  }

  .footer-wrapper {
    background: #FFF;
  }
}

.error-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .info {
    padding-top: 60px;

    @include respond(XS){
      padding-top: 0px;
    }
  }

  .error-code {
    width: 100%;
    text-align: center;
    font-size: 135px;
    font-weight: 200;
    color: $col_link_03;
    letter-spacing: 0.065em;
    margin-bottom: -10px;

    @include respond-down(L){
      font-size: 100px;
    }
    @include respond(XS){
      font-size: 75px;
    }
  }

  .lead {
    width: 100%;
    text-align: center;
    font-size: 33px;
    font-weight: 200;
    margin-bottom: 70px;

    @include respond-down(L){
      font-size: 24px;
      margin-bottom: 50px;
    }
    @include respond(XS){
      font-size: 18px;
    }
  }

  .wrapper-button {
    position: relative;
    text-align: center;

    .button {
      position: relative;
      z-index: 10;
    }

    svg {
      z-index: 5;
      bottom: 28px;
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;

      @include respond-down(L){
        bottom: 20px;
      }
      @include respond(XS){
        display: none;
      }
    }
  }

  .arrow {
    padding-top: 45px;
    width: 100%;
    text-align: center;

    svg {
      width: 60px;
      transform: rotate(270deg);
    }
  }

  .footer-space {
    height: 10px;
  }

}