.services{
  @include respond-property(padding-bottom, 87px, 56px,40px, 48px,53px);
  @include respond-property(padding-top, 100px, 64px,64px, 57px,50px);
  position: relative;
  .background{
    position: absolute;
    z-index: 1;
    width: 30%;
    height: 30%;
    svg{
      height: 100%;

      width: 100%;

      .cls-1{
        fill: $col_blue_01;
      }
    }
  }


  .list-services{
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    @include respond-property(margin-bottom,75px,62px,22px,30px,10px);
    flex-wrap: wrap;

    .item-services{
      display: flex;
      @include respond-property(flex, 0 0 305px, 0 0 213px, 0 0 50%, 0 0 50%, 0 0 50%);

      @include respond(M){
        margin-bottom: 28px;
      }
      @include respond(S){
        margin-bottom: 30px;
      }
      @include respond(XS){
        margin-bottom: 24px;
      }

      .inner{
        background-color: $col_nude_01;
        border-radius: 1000px;
        @include respond-property(padding,14px 16px,10px 14px,8px 15px,9px 16px,6px 9px);
        @include respond-property(flex, 0 0 100%, 0 0 100%, 0 0 213px, 0 0 238px, 0 0 133px);
        display: flex;
        align-items: center;
      }

      .circle{
        display: flex;
        align-items: center;
        justify-content: center;
        @include respond-property(width,  72px, 50px, 50px, 50px, 40px);
        @include respond-property(height, 72px, 50px, 50px, 50px, 40px);
        border: 1px solid $col_text_02;
        border-radius: 1000px;

        svg{
          width: 48%;

          .cls-1 {
            fill: $col_text_02;
          }
        }
      }

      .name{
        font-weight: 400;
        @include respond-property(margin-left,15px,15px,32px,27px,8px);
        color: $col_text_01;
        @include respond-property(font-size,19px,16px,16px,18px,12px);
      }

      @include respond-down(M){
        &.left{
          justify-content: flex-end;
          padding-right: 25px;
        }

        &.right{
          justify-content: flex-start;
          padding-left: 25px;
        }
      }

      @include respond(S){
        &.left{
          padding-right: 10px;
        }

        &.right{
          padding-left: 10px;
        }
      }

      @include respond(XS){
        &.left{
          padding-right: 3px;
        }

        &.right{
          padding-left: 3px;
        }
      }
    }
  }

  .bottom-block{
    display: flex;
    flex-wrap: wrap;

    @include respond(XS){
      align-items: center;
    }

    .wrapper-button{
      margin-bottom: 36px;
      flex: 0 0 100%;
      display: flex;
      justify-content: center;

      @include respond(XS){
        flex: 0 0 60%;
        margin-bottom: 0px;
        .button{
          padding: 13px 20px 13px;
        }
      }
    }

    .arrow{
      display: flex;
      flex: 0 0 100%;
      justify-content: center;

      @include respond(XS){
        flex: 0 0 40%;
      }

      svg{
        transform: rotate(-90deg);
        height: 40px;


        @include respond-down(L){
          height: 34px;
        }
        @include respond-down(M){
          height: 28px;
        }

        @include respond(XS){
          transform: rotate(-180deg);
          height: 28px;
        }

        .cls-1{
          fill: $col_blue_01;
        }
      }
    }
  }


}