.attention {
  position: relative;
  background: #ffc;
  border-radius: 3px;
}

.product-page {
  background: #F9F9F9;

  .menu-panel {
    display: none;
    margin: 0 5%;

    @include respond-down(M) {
      display: flex;
    }
  }

  .form .form-result {
    .head {
      color: $col_text_01 !important;
    }
  }


  // UI KIT fix
  letter-spacing: 0;

  // UI KIT fix
  .button {
    letter-spacing: 0;
    font-size: 19px;
    height: 62px;
    padding: 0 26px;

    @include respond-down(L){
      font-size: 15px;
      height: 44px;
      padding: 0 20px;
    }
    @include respond-down(S){
      padding: 0 35px;
      height: 52px;
      font-size: 16px;
    }
    @include respond(XS){
      height: 42px;
      font-size: 14px;
    }

    &.hidebound {
      height: 42px;

      @include respond-down(L){
        height: 30px;
      }
    }
  }

  .separated {
    display: flex;

    .panel-wrapper {
      flex: 0 0 17.8%;

      @include respond-down(L){
        flex: 0 0 19.8%;
      }
      @include respond-down(M){
        display: none;
      }
    }

    .panel {
      position: fixed;
      padding: 38px;
      top: 0;
      display: flex;
      justify-content: center;

      width: 17.8%;

      @include respond-down(L){
        width: 19.8%;
      }
      @include respond-down(M){
        display: none;
      }

      .back {
        width:  76px;
        height: 76px;
        border-radius: 70px;
        background: $col_blue_01;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px 2px 26px 2px;
        transition: background 0.15s linear;
        cursor: pointer;

        svg {
          width: 36px;

          @include respond-down(L){
            width: 26px;
          }
        }

        @include respond-down(L){
          width:  54px;
          height: 54px;
        }

        @include respond-up(M) {
          &:hover {
            background: $col_blue_02;
          }
        }
      }

      .products-panel {
        width: 102px;
        border-radius: 50px;
        margin: 0 -11px;
        background: #FFF;
        padding: 1px;

        @include respond-down(L){
          width: 74px;
        }

        .item {
          width: 80px;
          height: 80px;
          border-radius: 80px;
          background: #F9F9F9;
          margin: 10px;
          position: relative;
          cursor: pointer;
          display: block;
          transition: background 0.15s linear;

          @include respond-down(L){
            width:  60px;
            height: 60px;
            margin: 7px;
          }

          .image {
            position: absolute;
            left:   -4px;
            top:    -4px;
            right:  -4px;
            bottom: -4px;
            background: no-repeat center center;
            background-size: contain;
            transform: scale(0.65);
            filter: grayscale(100%);
            transition: filter 0.15s linear, transform 0.25s ease-in-out;
          }

          &.current {
            background: #E4E4E4;

            .image {
              transform: scale(1.0);
              filter: grayscale(0%);

              @include respond-down(L){
                transform: scale(1);
              }
            }
          }

          @include respond-up(M) {
            &:hover {
              background: #E4E4E4;

              .image {
                filter: grayscale(0%);
              }
            }
          }
        }
      }
    }

    .page {
      flex: 1 1 100%;
      overflow: hidden;

      .product-container {
        padding: 0 5.6% 35px 8.2%;

        @include respond-down(L){
          padding: 0 7% 0 6.5%;
        }
        @include respond-down(M){
          padding: 0 7.2% 0 7.2%;
        }

        .header-block {
          padding: 52px 0 40px 0;

          @include respond-down(L) {
            padding: 36px 0 50px 0;
          }
          @include respond-down(M){
            display: none;
          }
        }

        &.white {
          background: #FFF;
          margin-bottom: 108px;

          @include respond-down(L){
            margin-bottom: 68px;
          }
          @include respond-down(M){
            padding: 6% 6.5% 3% 6.5%;
            margin: 5px 5% 58px 5%;
            border-radius: 10px;
          }
          @include respond-down(S){
            margin-bottom: 63px;
          }
          @include respond(XS){
            margin-bottom: 37px;
            padding: 0;
            background: transparent;
          }
        }

        &.category-white {
          padding-bottom: 20px;

          .category-header {
            .desc {
              @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);
              color: $col_text_02;
              line-height: 1.5em;
              padding-top: 15px;

              padding-left: 80px;

              @include respond-down(L){
                padding-left: 30px;
              }
              @include respond-down(M){
                padding-left: 0;
              }
            }
          }
        }

        .white-mobile {
          @include respond(XS){
            background: #FFF;
            padding: 9% 4.8% 0% 4.8%;
            border-radius: 10px;
            margin-bottom: 20px;
          }
        }

        .product-intro {
          display: flex;
          align-items: center;
          margin-bottom: 68px;

          .list-type-dot {
            padding: 10px 0;

            .item {
              line-height: 19px;
              margin-bottom: 10px;

              &:before {
                top: 5px
              }

              text-align: left;
              @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);

              @include respond-down(L){
                color: $col_text_01;
              }
            }
          }

          @include respond-down(L){
            margin-bottom: 45px;
          }
          @include respond-down(S){
            margin-bottom: 0px;
            flex-wrap: wrap;
          }

          .gray-circle {
            width:  454px;
            height: 454px;
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;

            @include respond-down(L){
              width:  324px;
              height: 324px;
            }
            @include respond-down(M){
              width:  244px;
              height: 244px;
            }
            @include respond-down(S){
              width:  288px;
              height: 288px;
              margin: auto;
            }
            @include respond(XS){
              width:  200px;
              height: 200px;
            }

            &:after {
              content: '';
              position: absolute;
              background: #EFEFEF;
              border-radius: 500px;
              z-index: 10;
              left:   0;
              right:  0;
              top:    0;
              bottom: 0;
            }

            .image {
              position: absolute;
              left:   40px;
              right:  40px;
              top:    40px;
              bottom: 40px;
              z-index: 20;
              background: no-repeat center center;
              background-size: contain;

              @include respond-down(M){
                left:   0px;
                right:  0px;
                top:    0px;
                bottom: 0px;
              }
              @include respond-down(M){
                left:   -10px;
                right:  -10px;
                top:    -10px;
                bottom: -10px;
              }
            }
          }

          .into-text {
            padding-left: 80px;
            width: 100%;

            @include respond-down(L){
              padding-left: 30px;
            }
            @include respond-down(M){
              padding-top: 40px;
            }
            @include respond-down(S){
              flex: 0 0 100%;
              text-align: center;
              padding: 32px;
            }

            .h1 {
              @include respond-property(font-size, 33px, 21px, 22px, 24px, 18px);
              font-size: 33px;
              color: $col_text_01;
              font-weight: 400;
              margin-bottom: 16px;

              @include respond-down(L){
                margin-bottom: 12px;
              }
              @include respond-down(M){
                margin-bottom: 8px;
              }
            }

            .h2 {
              @include respond-property(font-size, 30px, 19px, 19px, 19px, 17px);
              color: #444;

              margin-bottom: 46px;

              @include respond-down(L){
                margin-bottom: 27px;
              }
              @include respond-down(M){
                margin-bottom: 20px;
              }
            }

            .quantity-price {
              display: flex;

              @include respond-down(M){
                flex-direction: column;
              }

              .table {
                .t-row {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding-right: 16px;
                  position: relative;
                  height: 60px;
                  margin-bottom: 10px;

                  @include respond-down(L){
                    height: 40px;
                    margin-bottom: 6px;
                  }
                  @include respond-down(M){
                    padding-right: 10px;
                  }

                  &.selectable {
                    &:after {
                      content: '';
                      z-index: 10;
                      left: 0;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      position: absolute;
                      border-radius: 60px;
                      background: #F9F9F9;
                      opacity: 0;
                      transition: opacity 0.2s ease;

                      @include respond-down(M){
                        opacity: 0;
                      }
                    }

                    @include respond-down(M){
                      &:nth-child(odd) {
                        &:after {
                          opacity: 1;
                        }
                      }
                    }

                    @include respond-up(L){
                      &:hover:after {
                        opacity: 1;
                      }
                    }
                  }

                  .col {
                    @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);
                    position: relative;
                    z-index: 20;
                    min-width: 80px;
                    flex: 0 0 80px;
                    color: #848F92;
                    text-align: center;

                    @include respond-down(L){
                      min-width: 68px;
                      flex: 0 0 68px;
                    }

                    &.price {
                      @include respond-property(font-size, 22px, 16px, 16px, 20px, 16px);
                      font-weight: bold;
                      color: #0073FF;
                      margin-left: 10px;

                      @include respond-down(L){
                        margin-left: 6px;
                      }

                      &:not([data-voltage="1"]) {
                        display: none;
                      }
                    }

                    &.count {
                      color: #0A1F25;
                    }

                    &.line {
                      height: 1px;
                      background: #DFDFDF;
                    }

                    &.full {
                      width: 100%;
                      flex: 1 1 100%;
                    }
                  }
                }

                &.price-list {
                  flex: 1 1 100%;

                  @include respond-down(M){
                    flex: 0 0 100%;
                  }
                }

                &.voltage-block {
                  flex: 1 1 60%;
                  margin-right: 20px;

                  @include respond-down(M){
                    flex: 0 0 100%;
                    margin-right: 0;
                  }

                  .t-row {
                    .col {
                      white-space: nowrap;
                      text-align: left;
                    }
                  }

                  .voltage-items-wrapper {
                    position: relative;

                    @include respond-down(M){
                      height: 40px;
                      margin-bottom: 10px;
                    }

                    .voltage-items {
                      transition: max-height 0.2s linear;

                      @include respond-down(M){
                        display: block;
                        position: absolute;
                        overflow: hidden;
                        background: #FFF;
                        z-index: 30;
                        border: 2px solid #0073FF;
                        border-radius: 20px;
                        width: 100%;
                        max-height: 40px;
                      }
                      @include respond-down(S){
                        border-radius: 23px;
                        max-height: 46px;
                      }

                      &.opened {
                        max-height: 260px;

                        .active svg {
                          transform: rotate(180deg);
                        }
                      }
                    }
                  }

                  .voltage-items {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .voltage-item {
                      cursor: pointer;
                      min-width: 160px;
                      height: 46px;
                      border-radius: 50px;
                      margin-bottom: 24px;
                      font-weight: 400;
                      border: 1px solid #E3E3E3;
                      display: flex;
                      align-items: center;
                      justify-content: space-around;
                      transition: background-color 0.25s ease,
                        border 0.25s ease,
                        box-shadow 0.25s ease;
                      box-shadow: inset 0 0 0 1px transparent;

                      &.active {
                        display: none;
                        position: relative;

                        svg {
                          transition: transform 0.25s ease-in-out;
                          position: absolute;
                          right: 15px;
                          top: 50%;
                          margin-top: -4px;
                        }

                        @include respond-down(M){
                          display: flex;
                        }
                      }

                      &.current {
                        @include respond-up(L){
                          box-shadow: inset 0 0 0 1px #0073FF;
                          border: 1px solid #0073FF;
                        }
                        @include respond-down(M){
                          background: #F9F9F9;
                          border-radius: 0;
                        }
                      }

                      &:hover {
                        @include respond-up(L){
                          background: #F9F9F9;
                        }
                      }

                      @include respond-down(L){
                        min-width: 140px;
                        height: 40px;
                        margin-bottom: 12px;
                      }
                      @include respond-down(M){
                        font-size: 16px;
                        width: 100%;
                        margin-bottom: 0;
                        border: none;
                        height: 36px;
                        box-shadow: none;
                        color: #0A1F25;
                      }
                      @include respond-down(S){
                        height: 42px;
                      }
                      @include respond-down(S){
                        font-size: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .description-part {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          @include respond-down(S){
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 45px;
          }

          .lead {
            @include respond-property(font-size, 19px, 16px, 16px, 16px, 14px);
            color: $col_text_02;
            line-height: 1.5em;
            margin-bottom: 54px;
            padding-right: 80px;
            font-weight: 400;

            b, strong {
              font-weight: bold;
            }

            a {
              text-decoration: underline;
            }

            @include respond-down(L){
              color: $col_text_01;
              padding-right: 0;
              margin-bottom: 15px;
            }
            @include respond-down(M){
              margin-bottom: 20px;
              line-height: 1.7em;
            }
            @include respond-down(S){
              margin-bottom: 25px;
              padding-left: 10%;
              padding-right: 10%;
            }
          }

          .button {
            white-space: nowrap;
            margin-left: 30px;

            @include respond-down(M){
            }
            @include respond-down(S){
            }
          }
        }

        .info-block {
          @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);

          padding-bottom: 15px;

          .heading {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            @include respond(XS){
              flex-wrap: wrap;
            }

            .h3 {
              @include respond-property(font-size, 44px, 32px, 27px, 27px, 24px);
              flex-grow: 0;
              flex-shrink: 0;
              font-size: 44px;
              font-weight: 200;
            }

            .line {
              flex: 1 1 99%;
              height: 1px;
              width: auto;
              display: inline-block;
              background: #DFDFDF;
              margin-top: 11px;
              margin-left: 25px;

              @include respond-down(L){
                margin-top: 8px;
              }
              @include respond(XS){
                flex: 0 0 100%;
                margin-left: 0;
              }
            }
          }

          .data {
            padding: 20px 0;

            @include respond(XS){
              padding-bottom: 0;
            }

            .spec-item {
              @include respond(XS){
                margin-left: 0;
                border-radius: 30px;
                padding-right: 20px;

                &:nth-child(even) {
                  background: #FFF;
                }
              }

              &.spec-header {
                text-transform: uppercase;
                font-weight: 600;
                color: $col_text_01;
                margin-top: 52px;
                font-size: 22px;

                &:first-child {
                  margin-top: 0;
                }

                @include respond-down(L){
                  margin-top: 40px;
                  font-size: 16px;
                }
                @include respond(S){
                  margin-top: 50px;
                }
                @include respond(XS){
                  margin-top: 28px;
                }

                .header-area {
                  min-height: 58px;
                  display: flex;
                  align-items: center;
                  z-index: 50;

                  @include respond-down(L){
                    min-height: 42px;
                  }
                }
              }
            }


            &.pdf {
              display: flex;
              justify-content: space-between;
              padding: 40px 0 40px 0;
              margin-bottom: 0;

              @include respond-down(L){
                padding: 22px 0 22px 0;
              }
              @include respond-down(M){
                padding: 28px 0 20px 0;
              }
              @include respond(XS){
                flex-wrap: wrap;
                justify-content: space-around;
              }

              .icon {
                display: flex;
                align-items: center;

                @include respond(XS){
                  margin-bottom: 32px;
                }

                .pdf {
                  cursor: pointer;
                }

                .pdf svg {
                  width: 42px;

                  @include respond-down(L){
                    width: 34px;
                  }
                  @include respond(XS){
                    width: 30px;
                  }
                }

                .helper {
                  font-weight: 400;
                  padding-left: 26px;

                  @include respond-down(L){
                    padding-left: 20px;
                  }

                  .bold {
                    display: block;
                    margin-bottom: 8px;
                    color: #222;
                    cursor: pointer;

                    @include respond-down(L){
                      font-size: 14px;
                    }
                  }

                  .file-size {
                    display: block;
                    color: #D2D2D2;
                    font-size: 16px;

                    @include respond-down(L){
                      font-size: 11px;
                    }
                    @include respond(XS){
                      font-size: 12px;
                    }
                  }
                }
              }

              .button {
                svg {
                  width: 18px;
                  margin-left: 14px;

                  @include respond-down(L){
                    width: 16px;
                    margin-left: 9px;
                  }
                }
              }
            }
          }
        }
      }

      .make-order {
        @include respond-property(padding-bottom, 100px, 80px, 60px, 80px, 50px);

        .h2 {
          font-size: 64px;
          font-weight: 200;
          margin-bottom: 39px;
          color: $col_text_01;

          @include respond-down(L){
            font-size: 46px;
            margin-bottom: 24px;
          }
          @include respond-down(M){
            font-size: 36px;
          }
        }

        .lead {
          font-size: 19px;
          font-weight: 400;
          color: $col_text_02;
          margin-bottom: 70px;
          padding-right: 25%;
          line-height: 1.7em;

          @include respond-down(L){
            font-size: 14px;
            margin-bottom: 40px;
          }
          @include respond-down(M){
            color: $col_text_01;
          }
        }
      }
    }
  }
}

.category-list {
  .page {
    .product-container {
      .category-header {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 50px 0;

        @include respond-down(M){
          padding: 0;
          margin-bottom: 20px;
        }
        @include respond-down(S){
          flex-wrap: wrap;
        }

        .cat-title {
          flex: 1 1 auto;
          width: auto !important;
          margin-top: -10px;
          white-space: nowrap;

          @include respond-property(font-size, 64px, 46px, 36px, 36px, 24px);
          @include respond-down(L){
            margin-top: -4px;
          }
          @include respond-down(S){
            margin-bottom: -14px;
          }
        }

        .blue-line {
          flex: 1 1 100%;
          display: block;
          margin: 0 25px;
          height: 2px;
          background: #0073FF;
          width: auto;

          @include respond-down(L){
            height: 1px;
          }
          @include respond-down(S){
            flex: 1 1 60%;
            margin: 0 25px 0 0;
          }
          @include respond(S){
            height: 2px;
          }
        }

        svg {
          flex: 0 0 auto;
          width: 90px;

          @include respond-down(L){
            width: 56px;
          }
          @include respond-down(XS){
            width: 36px;
          }
        }
      }

      .category-texts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        @include respond-down(M){
          flex-wrap: wrap;
        }

        p {
          margin-bottom: 1em;
        }

        ul {
          list-style: disc;
          padding: 0 0 20px 28px;

          @include respond-down(M){
            padding: 0 0 14px 18px;
          }
        }

        b, strong {
          font-weight: bold;
        }

        .desc {
          width: 47%;
          font-weight: 400;
          font-size: 19px;

          @include respond-down(L){
            font-size: 14px;
          }
          @include respond-down(M){
            width: 100%;
            margin-bottom: 25px;
          }
        }
      }

      .category-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .product-cart-item {
          width: 47%;
          position: relative;
          @include respond-property(margin-bottom, 43px, 25px, 38px, 50px, 43px);

          @include respond-down(S){
            width: 100%;
          }

          .cart-item {
            width: 100%;
            padding-top: 71.1538%;
            // background: rgba(255,0,0,0.5);

            @include respond-down(M){
              padding-top: 129%;
            }
            @include respond(S){
              padding-top: 90.8%;
            }

            .xl-l {
              display: block;

              @include respond-down(M){
                display: none;
              }
            }

            .m-xs {
              display: none;

              @include respond(M){
                display: block;
              }
              @include respond(XS){
                display: block;
              }
            }

            .s {
              display: none;

              @include respond(S){
                display: block;
              }
            }

            & > svg {
              position: absolute;
              z-index: 10;
              width: 100%;
              height: 150%;
              left: 0;
              right: 0;
              top: -54px;
              transform: scale(1.256);
              opacity: 0.6;

              @include respond-down(M){
                transform: scale(1.46);
              }
              @include respond(S){
                transform: scale(1.3);
              }
            }

            .content-cart-wrapper {
              cursor: pointer;
              position: absolute;
              z-index: 20;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              display: flex;

              @include respond-down(M){
                flex-direction: column;
              }

              .cart-image {
                flex: 0 0 38.46%;

                @include respond-down(M){
                  flex: 0 0 26%;
                }
                @include respond(XS){
                  flex: 0 0 22%;
                }

                .circle {
                  width: 140px;
                  height: 140px;
                  background: $col_blue_01;
                  border-radius: 100px;
                  position: absolute;
                  left: -30px;
                  top: 50%;
                  margin-top: -70px;
                  transition: opacity 0.15s ease, transform 0.35s ease;
                  opacity: 0;
                  transform: scale(0.45);

                  @include respond-down(L){
                    left: -10px;
                    margin-top: -50px;
                    width: 100px;
                    height: 100px;
                  }
                  @include respond-down(M){
                    display: none;
                  }
                }

                .image {
                  width:  160px;
                  height: 160px;
                  left: -40px;
                  top: 50%;
                  margin-top: -80px;
                  position: absolute;
                  z-index: 30;
                  background: no-repeat center center;
                  background-size: contain;

                  @include respond-down(L){
                    margin-top: -60px;
                    left: -20px;
                    width: 120px;
                    height: 120px;
                  }
                  @include respond-down(M){
                    margin-left: -60px;
                    margin-top: 0;
                    top: -50px;
                    left: 50%;
                  }
                  @include respond(S){
                    margin-left: -90px;
                    top: -70px;
                    width:  180px;
                    height: 180px;
                  }
                  @include respond(XS){
                    margin-left: 0;
                    top: -70px;
                    left: 25%;
                    width:  50%;
                    height: 200px;
                  }
                }
              }

              .product-content {
                position: relative;
                flex: 1 1 61.54%;
                padding: 30px 0;
                margin-right: 30px;

                @include respond-down(L){
                  margin-right: 17px;
                }
                @include respond-down(M){
                  margin: 0 21px 21px 21px;
                }

                .short {
                  text-transform: uppercase;
                  font-weight: 600;
                  color: #0A1F25;
                  font-size: 28px;

                  @include respond-down(L){
                    font-size: 21px;
                  }
                  @include respond-down(M){
                    text-align: center;
                  }
                }

                .long {
                  font-size: 22px;
                  font-weight: 200;
                  color: #848F92;
                  margin-bottom: 20px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @include respond-down(L){
                    font-size: 16px;
                    margin-bottom: 14px;
                  }
                  @include respond-down(M){
                    text-align: center;
                  }
                }

                .round-items {
                  .list-type-dot .item {
                    font-size: 16px;
                    color: #0A1F25;
                    line-height: 1.4em;
                    padding-left: 16px;
                    margin-bottom: 8px;
                    font-weight: 400;

                    @include respond-down(L){
                      font-size: 12px;
                    }
                    @include respond-down(S){
                      font-size: 14px;
                      margin-bottom: 13px;
                    }

                    &:before {
                      top: 9px;
                      width: 6px;
                      height: 6px;

                      @include respond-down(L){
                        top: 6px;
                        width: 6px;
                        height: 6px;
                      }
                    }
                  }
                }

                .bottom-buttons {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  padding: 21px 0 24px 0;
                  border-top: 1px solid #DFDFDF;

                  @include respond-down(L){
                    min-width: 240px;
                    left: auto;
                    border: none;
                    padding: 0 0 21px 0;
                  }
                  @include respond-down(M){
                    padding: 0;
                    left: 0;
                  }

                  .button {
                    width: 130px;
                    height: 45px;
                    font-weight: 400;
                    font-size: 15px;

                    @include respond-down(L){
                      width: auto;
                      height: 30px;
                      font-size: 14px;
                    }

                    @include respond(S){
                      width: 164px;
                      height: 52px;
                      font-size: 16px;
                    }

                    &.pdf {
                      border: 1px solid $col_blue_01;
                      background: #fff;
                      color: $col_link_03;

                      @include respond(XS){
                        border: none;
                        padding: 0 10px 0 18px;

                        .helper {
                          text-decoration: underline;
                        }
                      }

                      svg {
                        float: left;
                        width: 17px;
                        position: absolute;
                        left: 20px;

                        @include respond-down(L){
                          width: 13px;
                          left: 12px;
                        }
                        @include respond(S){
                          width: 20px;
                          left: 22px;
                        }

                        .red {
                          fill: #f35941;
                        }
                        .gray {
                          fill: #d2d2d2;
                        }
                      }

                      .helper {
                        padding-left: 25px;

                        @include respond-down(L){
                          padding-left: 12px;
                        }
                      }

                      &:hover {
                        background: $col_blue_01;
                        color: #fff;

                        svg {
                          .red, .gray {
                            fill: #fff;
                          }
                        }
                      }
                    }

                    &.right {
                      float: right;

                      @include respond(XS){
                        width: 107px;
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .content-cart-wrapper {
                .cart-image {
                  .circle {
                    opacity: 1;
                    transform: scale(1);
                  }
                }
              }
            }

          }
        }
      }
    }
  }
}

body {
  .product-container {
    .nav, .info-block {
      opacity: 1;
      transition: opacity 0.15s linear;
    }

    .gray-circle:after {
      opacity: 1;
      transform: scale(1);
      transition: opacity 0.15s linear, transform 0.25s ease;
    }

    .into-text {
      opacity: 1;
      transition: opacity 0.15s linear;

      .h1 {
        transform: translateY(0);
        transition: transform 0.15s linear;
      }
      .lead {
        transition-delay: 0.10s;
      }
    }

    .image {
      transform: scale(1);
      opacity: 1;
      transition: transform 0.25s ease, opacity 0.20s linear;
    }

  }

  &.loading {
    .product-container {
      .nav, .info-block {
        opacity: 0;
      }

      .gray-circle:after {
        opacity: 0;
        transform: scale(1.2);
      }

      .into-text {
        opacity: 0;

        .h1 {
          transform: translateY(-18px);
        }
      }

      .image {
        transform: scale(0.75);
        opacity: 0;
      }
    }
  }
}