.header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .nav {
    font-size: 18px;
    font-weight: 400;
    margin: 0 -17px;
    line-height: 1.2em;

    @include respond-down(L){
      font-size: 15px;
    }

    .item {
      color: $col_text_01;
      padding: 0 17px;
      position: relative;
      transition: color 0.35s ease;
      display: inline-flex;
      align-items: center;

      svg {
        height: 24px;
        margin-left: 14px;
      }
      
      &:hover {
        color: #0073FF;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    .button {
      margin-left: 60px;

      @include respond-down(L){
        margin-left: 24px;
      }
    }
  }
}
