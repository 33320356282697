.button{

  @include respond-property(font-size, 20px, 15px, 15px, 16px, 14px);
  @include respond-property(padding, 17px 30px 17px, 12px 19px 12px, 13px 30px 13px, 13px 30px 13px, 13px 47px 13px);
  background-color: $col_blue_01;
  border-radius: 1000px;
  color: white;
  text-decoration: none;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: $default-transition;
  border: none;
  font-family: $font;
  cursor: pointer;

  &:hover{
    background-color: $col_blue_02;

    @include respond-down(M){
      background-color: $col_blue_01;
    }
  }

  &.hidebound{
    @include respond-property(padding, 11px 23px 9px, 6px 17px 5px, 6px 17px 5px, 6px 17px 5px, 6px 17px 5px);
  }

  &.white{
    box-shadow: 0px 0px 0px 1px $col_blue_01;
    background-color: white;
    color: $col_blue_01;
    border-radius: $col_blue_01;

    &:hover{
      background-color: $col_blue_01;
      color: white;

      @include respond-down(M){
        background-color: white;
        color: $col_blue_01;
      }
    }
  }

}