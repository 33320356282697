.form-block{
  background: $col_text_01;
  @include respond-property(padding, 68px 0 68px,  62px 0 54px, 80px 0 65px, 70px 0 70px, 44px 0 40px);


  .inner-block{
    display: flex;

    @include respond-down(M){
      flex-wrap: wrap;
    }

    .content-block{
      flex: 0 0 338px;

      @include respond(L){
        flex: 0 0 300px;
      }

      @include respond-down(M){
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 58px;
      }
      @include respond(XS){
        margin-bottom: 28px;
      }

      .title{
        color: white;
        display: block;
        @include respond-property(margin-bottom, 32px, 29px, 0px,0px,0px);//ok
        @include respond-property(font-size, 64px, 46px, 36px,36px,24px);//ok
      }

      .text{
        color: white;
        font-size: 19px;
        @include respond-property(font-size, 19px, 14px, 16px,16px,0px);//ok
        line-height: 1.6;
        opacity: 0.8;
      }
    }

  }



}