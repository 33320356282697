.list-type-number {

  list-style-type: none; /* Убираем исходные маркеры */
  counter-reset:  item; /* Обнуляем счетчик списка */
  .item{
    color: $col_text_02;
    margin-bottom: 15px;
    @include respond-property(font-size, 19px, 15px, 15px, 12px, 12px);
    @include respond-property(padding-left, 55px, 48px, 48px, 42px,42px);
    @include respond-property(line-height, 31px, 28px,28px, 24px,24px);
    position: relative;

    &:before{
      position: absolute;
      left: 0;
      top: 0;
      color: white;
      background-color: $col_blue_01;
      border-radius: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include respond-property(font-size, 16px, 15px, 15px, 12px, 12px);
      @include respond-property(width, 31px, 28px,28px, 24px,24px);
      @include respond-property(height, 31px, 28px,28px, 24px,24px);
      content: counter(item); /* Добавляем к числам скобку */
      counter-increment: item; /* Задаём имя счетчика */
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

}

.list-type-dot {
  list-style-type: none;

  .item{
    color: $col_text_02;
    margin-bottom: 15px;
    @include respond-property(font-size, 19px, 15px, 15px, 14px, 14px);
    @include respond-property(padding-left, 28px, 24px, 24px, 16px, 16px);
    @include respond-property(line-height, 28px, 21px, 21px, 18px, 18px);
    position: relative;

    &:before{
      position: absolute;
      left: 0;
      content: '';
      background-color: $col_blue_01;
      border-radius: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include respond-property(top, 11px, 8px, 8px, 7px, 7px);
      @include respond-property(width, 9px, 8px, 8px, 7px,7px);
      @include respond-property(height, 9px, 8px, 8px, 7px,7px);
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.slider-array {
  display: flex;
  transition: background-color 0.2s, background-image 0.2s;
  border: 1px solid $col_grey_01;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include respond-property(width, 56px ,41px,41px,47px, 35px);
  @include respond-property(height, 47px,34px,35px,40px, 30px);
  background-color: #FFF;


  svg {
    width: 7.2px;
    transition: transform 0.55s ease;

    .cls-1{
      transition: all 0.2s;
      fill: none;
      stroke-width: 2px;
      stroke: #0A1F25;
    }
  }

  &.left{
    border-radius: 15px 0 0 15px;
  }

  &.right{
    border-radius: 0px 15px 15px 0;

    svg{
      transform: rotate(180deg);
    }
  }

  @include respond-up(M){
    &:hover{
      svg{
        .cls-1{
          stroke: $col_blue_01;
        }
      }
    }
  }

  &.active{
    border-color: $col_blue_01;
    background-color: $col_blue_01;

    svg{
      .cls-1{
        stroke: white;
      }
    }
  }

  &.left.animated {
    svg {
      transform: translateX(-5px);
      transition: transform 0.1s linear;
    }
  }
  &.right.animated {
    svg {
      transition: transform 0.1s linear;
      transform: translateX(5px) rotate(180deg);
    }
  }

}