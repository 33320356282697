form.form {
  flex: 1 1 auto;
  display: flex;
  font-weight: 400;
  position: relative;

  @include respond-down(M){
    flex-wrap: wrap;
  }

  .personal {
    position: absolute;
    bottom: 10px;
    right: 0;
    font-size: 12px;
    color: $col_text_02;

    a {
      color: $col_text_02;
      text-decoration: underline;
      transition: color 0.15s linear;

      @include respond-up(M){
        &:hover {
          color: $col_link_03;
        }
      }
    }

    @include respond-down(M){
      text-align: center;
      left: 0;
    }
  }

  .wrapper-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1 1 auto;

    .wrapper-input {
      flex: 0 0 49%;
      margin-bottom: 20px;

      input {
        @include respond-property(font-size, 19px, 15px, 14px, 14px, 14px);
        @include respond-property(padding, 22px 30px 20px, 15px 21px 14px, 15px 21px 14px, 15px 20px 14px, 15px 20px 14px);
        border-radius: 1000px;
        border: 1px solid;
        width: 100%;
        color: $col_text_01;
        font-family: $font;
        border-color: $col_text_02;
        background: transparent;
        transition: border-color 0.4s;

        &::placeholder {
          color: $col_text_02;
          font-family: $font;
        }
      }

      textarea {
        color: $col_text_01;
        resize: none;
        width: 100%;
        border: none;
        @include respond-property(font-size, 19px, 15px, 14px, 14px, 14px);

        height: 88px;
        background: transparent;

        &::placeholder {
          color: $col_text_02;
        }
      }

      &.textarea {
        @include respond-property(padding, 22px 30px 20px, 15px 21px 14px, 15px 21px 14px, 15px 20px 14px, 15px 20px 14px);
        flex: 0 0 100%;
        padding: 10px;
        border: 1px solid $col_text_02;
        border-radius: 31px;

        @include respond(XS){
          margin-bottom: 10px;
        }
      }

      @include respond(XS){
        flex: 0 0 100%;
      }
    }

    .signature-form {
      flex: 0 0 100%;
      color: $col_text_02;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;



      .mandatory{
        display: flex;
        flex: 0 0 111px;
        margin-right: 15px;
        @include respond-property(font-size,14px,12px,12px,12px,12px);
        margin-bottom: 10px;
        @include respond-down(L){
          flex: 0 0 100%;
          margin-right: 0;
        }

        @include respond(XS){
          justify-content: flex-end;
        }
      }

      .personal{
        display: flex;
        @include respond-property(font-size,14px,12px,12px,12px,12px);
      }


    }
  }

  &.black {
    padding-left: 6%;

    @include respond(L){
      padding-left: 3%;
    }

    @include respond-down(M){
      padding-left: 0;
    }

    .wrapper-input {
      input {
        color: white;
      }

      textarea {
        color: white;
      }
    }
  }

  .wrapper-button{
    display: flex;
    flex: 0 0 295px;
    align-items: flex-end;
    padding-bottom: 48px;
    position: relative;

    .form-result {
      position: absolute;
      left: 89px;
      right: 0;
      top: 0;
      padding-top: 15px;
      font-size: 19px;

      @include respond-down(L){
        font-size: 14px;
        left: 155px;
      }

      @include respond-down(M){
        position: relative;
        left: 0;
        text-align: center;
        right: 0;
        width: 100%;
        padding-top: 0;
        margin-bottom: 19px;
      }

      .head {
        color: rgba(255,255,255,0.8);
        margin-bottom: 18px;

        @include respond-down(M){
          margin-bottom: 4px;
        }
      }

      .txt {
        color: #848F92;
      }
    }

    .arrow-dot{
      flex: 1 1 auto;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        height: 40px;
      }
    }

    .button-width{
      widows: 125px;
      flex: 0 0 206px;

      .button{
        width: 100%;
      }
    }


    @include respond(L){
      flex: 0 0 280px;
      padding-bottom: 60px;

      .arrow-dot{
        height: 45px;

        svg{
          height: 28px;
        }
      }

      .button-width{
        flex: 0 0 125px;
      }

    }

    @include respond-down(M){
      flex-wrap: wrap;
      flex: 1 1 auto;


      .arrow-dot{
        flex: 0 0 100%;
        height: 42px;
        margin-bottom: 23px;

        svg{
          width: 42px;
          transform: rotate(90deg);
        }
      }
      .button-width {
        margin-bottom: 31px;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;

        .button{
          width: 138px;
        }
      }

    }
  }

  &.error {
    .wrapper-fields{
      .wrapper-input{

        input.required {

          position: relative;
          border-color: $col_red_01;

        }
      }

    }

  }
}

.form .os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
  background: $col_link_03;

  &:active, &:hover {
    background: $col_blue_02 !important;
  }
}

.form {
  .form-result {
    height: 0;
    opacity: 0;
    transition: opacity 0.35s linear, height 0.15s linear;
    transition-delay: 0.5s;
  }
  .button {
    position: relative;

    .success {
      position: absolute;
      left: 50%;
      margin-left: -11px;
      top: 50%;
      margin-top: -8px;
      opacity: 0;
      transition: opacity 0.35s linear;

      svg {
        width: 22px;
      }
    }

    .process {
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      transition: opacity 0.35s linear;
      opacity: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: 0;
    }
  }
}

.process {
  .button {
    cursor: default;

    .text{
      opacity: 0;
    }
    .process {
      opacity: 1;
    }
  }
}

.success {
  .form-result {
    height: auto;
    opacity: 1;
  }

  .button {

    .success {
      opacity: 1;
    }

    .text{
      opacity: 0;
    }
  }
}