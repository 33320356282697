.data-editable {
  .editor-templates {
    display: none;
  }

  [contenteditable=true] {
    position: relative;
    min-height: 100%;

    &:empty::before {
      opacity: 0.5;
      content:attr(data-x);
    }
    &:focus:empty::before {
      opacity: 0.7;
      content: 'Start write now';
    }

    &:after {
      position: absolute;
      left:   -6px;
      right:  -6px;
      top:    -6px;
      bottom: -6px;
      content: '';
      border-radius: 3px;
      cursor: text;
      border: 1px dashed rgba(0,0,250,0.3);
      background: rgba(0,250,0,0.3);
    }
  }

  [data-container] {
    position: relative;
  }

  .feature-text-item {
    color: $col_text_01;
    min-height: 38px;
    position: relative;
    font-weight: 400;
    line-height: 1.7em;
    margin-bottom: 30px;
    display: flex;

    &.cols-2 {
      @include respond-down(M) {
        flex-wrap: wrap;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .feature-text {
        flex: 0 0 50%;

        &:first-child {
          padding-right: 100px;

          @include respond-down(L) {
            padding-right: 50px;
          }
          @include respond-down(M) {
            padding-right: 0;
          }
        }

        @include respond-down(M) {
          flex: 0 0 100%;
          padding-right: 0;
        }
      }
    }

    .list-type-dot {
      .item {
        color: $col_text_01;

        &:last-child {
          margin-bottom: 15px;
        }

        .listable {
          position: relative;
          z-index: 15;
          min-width: 250px;
          display: inline-block;
        }

        .helper {
          position: absolute;
          z-index: 10;
          color: #333;
          font-size: 75%;
          left: 50px;
          bottom: -25px;
          opacity: 0.0;
          transition: opacity 0.15s linear;
          text-shadow: 0 0 2px #fff;
        }

        .listable:not(:empty):focus + .helper {
          opacity: 0.3;
        }
      }
    }
  }

  .spec-item {
    min-height:    58px;
    border-radius: 29px;
    display: flex;
    color: #222;
    transition: background 0.15s linear;
    flex-direction: column;
    margin: 0 0 0 -25px;
    padding-left: 25px;
    position: relative;

    @include respond-down(L) {
      min-height:    42px;
      border-radius: 21px;
      margin: 0 0 0 -23px;
      padding-left: 23px;
    }
    @include respond-down(M) {
      margin: 0 0 0 -20px;
      padding-left: 24px;
    }
    @include respond(XS) {
      min-height: 0;
    }

    @include respond-up(M) {
      &:not(.spec-header) {
        &:hover {
          background: #F9F9F9;
        }
        &:active{
          background: #f2f2f2;
        }
      }
    }

    .key-value {
      @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);
      display: flex;
      min-height: 58px;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;

      @include respond-down(L) {
        min-height:    42px;
      }
      @include respond(XS) {
        align-items: initial;
        flex-direction: column;
        padding: 10px 0;
        min-height: 0;
      }

      .children-list {
        .key-value {
          @include respond(XS) {
            padding: 2px 0;
          }
        }
      }

      .key {
        color: $col_text_01;

        @include respond-up(L) {
          flex: 1 1 75%;
        }
        @include respond(M) {
          flex: 1 1 70%;
        }
        @include respond(XS) {
          margin-bottom: 4px;
        }
      }

      .value {
        color: $col_text_02;

        @include respond-up(L) {
          flex: 1 1 25%;
        }
        @include respond(M) {
          flex: 1 1 30%;
        }
      }
    }

    .children-list {
      .key-value-wrapper {
        &:last-child {
          margin-bottom: 15px;
        }

        .key-value {
          @include respond(XS) {
            flex-direction: initial;
            align-items: center;
            padding: 8px 0;
          }

          .key {
            padding-left: 60px;
            position: relative;

            @include respond-down(L) {
              padding-left: 44px;
            }
            @include respond-down(S) {
              padding-left: 40px;
            }
            @include respond(XS) {
              margin-bottom: 0;
              padding-left: 15px;
            }

            &:after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 6px;
              background: $col_text_01;
              left: 30px;
              top: 50%;
              margin-top: -3px;

              @include respond-down(L) {
                left: 25px;
                width:  4px;
                height: 4px;
                margin-top: -2px;
              }
              @include respond-down(S) {
                left: 0px;
              }
            }
          }

          .value {
            @include respond(XS) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .key-value-wrapper {
      position: relative;

      .remove-item {
        top: 50%;margin-top: -18px;
        transform: scale(0.7);
      }
    }
  }

  .btn {
    opacity: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #FFF;
    cursor: pointer;
    border-radius: 30px;
    border: 1px solid #efefef;
    transition: border 0.10s linear, opacity 0.10s linear;
    z-index: 30;

    svg {
      width: 18px;

      path {
        transition: fill 0.10s linear;
      }
    }

    &:hover {
      border: 1px solid #c8c8c8;
    }
  }

  .add-list-item {
    position: absolute;
    margin-left: -18px;
    bottom: -18px;
    left: 50%;

    &:hover {
      border-color: #009;

      svg path {
        fill: #009
      }
    }
  }

  .remove-item {
    position: absolute;
    right: -18px;
    top: -10px;

    &:hover {
      border-color: #900;

      svg path {
        fill: #900
      }
    }
  }

  .feature-text-item, .spec-item {
    &:hover {
      .btn {
        opacity: 1;
      }
    }

    &.ui-sortable-helper {
      .btn {
        opacity: 0;
      }
    }
  }

  .editable-pane {
    align-items: center;
    user-select: none;
    justify-content: space-around;
    background: #fcfcfc;
    font-size: 24px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 0;
  }

  .add-group {
    display: flex;

    .editable-pane {
      border-radius: 0;
      flex: 1 1 50%;

      &:first-child {
        border-bottom-left-radius: 29px;
        border-top-left-radius: 29px;

        @include respond-down(L) {
          border-bottom-left-radius: 21px;
          border-top-left-radius: 21px;
        }
      }

      &:last-child {
        border-bottom-right-radius: 29px;
        border-top-right-radius: 29px;

        @include respond-down(L) {
          border-bottom-right-radius: 21px;
          border-top-right-radius: 21px;
        }
      }
    }
  }
}