@font-face {
    font-family: "Source Sans Pro";
    src: url("SourceSansPro-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("SourceSansPro-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("SourceSansPro-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("SourceSansPro-SemiBold.ttf");
    font-weight: 500;
    font-display: swap;
}
@font-face {
    font-family: "Source Sans Pro";
    src: url("SourceSansPro-Bold.ttf");
    font-weight: 600;
    font-display: swap;
}
