.menu-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 0;
  position: relative;
  letter-spacing: 0;
  font-weight: 400;
  overflow: hidden;

  @include respond-down(M){
    display: flex;
  }
  @include respond(XS){
    padding: 17px 0;
  }

  .logo-box {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 350;

    .svg {
      svg {
        width: 192px;

        @include respond-down(L){
          width: 138px;
        }

        @include respond(XS){
          width: 113px;
        }
      }

      .figure {
        fill: $col_text_01;
        transition: fill 0.25s ease-in-out;
      }

      .circle {
        fill: $col_blue_01;
        transition: fill 1s ease-in-out;

        &.black {
          fill: $col_text_01;
        }

        &.flash {
          fill: $col_blue_01;
          transition: fill 0.10s ease-in-out;
        }
      }

      @include respond-up(M) {
        &:hover {
          .circle {
            fill: #eee;

            &.black {
              fill: #aaa;
            }

            &.flash {
              fill: $col_blue_01;
              transition: fill 0.10s ease-in-out;
            }
          }

          .figure {
            fill: $col_text_01;
          }
        }
      }

    }

    .slogan {
      padding: 0 20px;
      font-size: 16px;
      font-weight: 400;
      color: $col_text_02;
      line-height: 1.37em;

      @include respond-down(L){
        font-size: 12px;
      }
    }
  }

  .menu-opener {
    display: none;
    position: relative;
    z-index: 350;
    transition: transform 0.28s ease;

    @include respond-down(M){
      display: flex;
    }

    .opener {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      background: $col_blue_01;
      border-radius: 50px;
      transition: transform 0.25s linear;

      i, i:after {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 5px;
        background: #FFF;
        margin: 2px;
        position: relative;
        transition: transform 0.15s ease, margin 0.15s ease;
      }

      i:after {
        margin: 0;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .menu-block {
    position: fixed;
    left: 100vw;
    right: 0;
    top: 0;
    height: 560px;
    width: 100%;
    bottom: 0;
    z-index: 300;
    background: #fff;
    display: flex;
    align-items: center;
    transition: left 0.4s ease;

    .menu-items {
      opacity: 0;
      transition: opacity 0.3s linear;
      padding: 20px 12% 0 12%;
      width: 100%;

      @include respond(XS){
        padding: 0px 7% 0 20%;
      }

      .item {
        text-transform: uppercase;
        color: $col_text_01;
        font-size: 16px;
        font-weight: 500;
        margin-right: 60px;
        margin-bottom: 8px;
      }

      .index {
        margin-bottom: 15px;
        display: inline-block;
      }

      .products {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 8px;

        .header {
          flex: 0 0 auto;
          margin-right: 10px;
          color: $col_text_02;
        }

        .line {
          margin-top: 5px;
          flex: 1 1 100%;
          height: 1px;
          display: inline-block;
          background: rgba(10, 31, 32, 0.2);
        }
      }

      .products-category {
        overflow: hidden;
        max-height: 38px;
        transition: max-height 0.35s ease;
        transition-delay: 0.10s;

        .header {
          display: flex;
          width: 280px;
          max-width: 100%;
          justify-content: space-between;
          font-weight: 500;
          margin: 8px 0;

          .count {
            width: 56px;
            height: 24px;
            border-radius: 24px;
            background: $col_blue_01;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            font-weight: 400;

            svg {
              width: 9px;
              transition: transform 0.25s;
              transform: rotate(180deg);

              .figure {
                stroke: #fff;
              }
            }
          }
        }

        .product-items {
          opacity: 0;
          transition: opacity 0.15s;
        }

        &.opened {
          max-height: 160px;

          .product-items {
            opacity: 1;
          }

          .count {
            svg {
              transform: rotate(0deg);
            }
          }
        }
      }

      .product-items {
        margin-bottom: 15px;

        .item {
          display: block;
          color: #848F92;
          padding-left: 12px;
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            top: 9px;
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background: $col_blue_01;
          }
        }
      }

    }

    .menu-footer {
      opacity: 0;
      transition: opacity 1s linear;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 30px 0;

      @include respond(S){
        padding: 40px 0;
      }

      .container {
        display: flex;
        justify-content: space-between;

        @include respond(XS){
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }

      .lang-select {
        @include respond(XS){
          margin: 0 -6px 14px -6px;
        }
      }

      .social {
        display: flex;

        @include respond(XS){
          flex: 0 0 100%;
          justify-content: space-between;
        }

        .item {
          display: flex;
          align-items: center;
          margin-left: 40px;

          @include respond(XS){
            margin-left: 0px;
          }

          .icon {
            width: 30px;
            border-radius: 30px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 30px;
            background: #E4E2E2;
            margin-right: 12px;

            @include respond(XS){
              margin-right: 7px;
            }

            svg {
              width: 18px;
            }

            &.linkedin {
              background: #0073FF;

              svg {
                width: 14px;
              }
            }
          }

          .helper {
            color: $col_text_02;
            font-size: 15px;
          }
        }
      }
    }
  }

  .header-block {
    .right {
      @include respond-down(M){
        display: none;
      }
    }
  }
}

body.menu-opened-in {
  [data-page-content] {
    overflow: hidden !important;
    height: 0 !important;

    &[data-main] {
      height: 160px !important;
    }
  }
}

body.menu-opened {
  overflow: hidden;

  .menu-panel {
    overflow: visible;

    .menu-opener {
      transform: rotate(180deg);

      i {
        margin: 1px;

        &:first-child, &:last-child {
          transform: translateY(-6px);

          &:after {
            transform: translateY(12px);
          }
        }
      }
    }

    .menu-block {
      left: 0;

      .menu-items {
        opacity: 1;
        transition-delay: 0.18s;
      }

      .menu-footer {
        opacity: 1;
        transition-delay: 0.18s;
      }
    }
  }
}

.main-page {
  .menu-panel {
    @include respond-property(padding-bottom, 110px, 85px, 80px, 60px, 75px);
    overflow: visible;

    // UI KIT fix
    letter-spacing: 0;

    .button {
      letter-spacing: 0;
      padding: 0 26px;

      &.hidebound {
        height: 42px;
        padding: 0 26px;

        @include respond-down(L){
          height: 30px;
          padding: 0 20px;
        }
      }
    }
    // UI KIT fix

    .logo-box {
      flex: 0 0 auto;
      margin-right: 7%;

      @include respond-down(L){
        margin-right: 2%;
      }

      @include respond-down(M){
        flex: 1 1 100%;
        margin-right: 0;
        padding: 0 1px;
      }
    }

    .header-block {
      flex: 1 1 100%;

      @include respond-down(M){
        flex: 0 0 90%;
        margin-right: 0px;
      }
      @include respond-down(XS){
        flex: 1 1 80%;
        margin-right: 40px;
      }

      .nav {
        display: flex;

        @include respond-down(M){
          width: 100%;
        }

        @include respond-down(S){
          display: none;
        }

        .link-wrapper {
          margin: 0 15px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          transition: background 0.2s ease;
          position: relative;

          &.whiter {
            background: #EFEFEF;
          }

          .arrow-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item {
              padding-right: 0;
              cursor: default !important;
            }

            svg {
              width: 10px;
              margin: 2px 20px 0 10px;
              transform: rotate(180deg);
              transition: transform 0.20s;

              .figure {
                stroke: $col_text_01;
                transition: stroke 0.15s;
              }
            }
          }

          .category-items-wrapper {
            visibility: hidden;
            position: absolute;
            left: 0;
            top: 30px;
            padding: 30px 0 0 0;
            transform: translateY(-12px);
            opacity: 0;
            transition: visibility 0.25s, opacity 0.10s linear, transform 0.25s ease;
            z-index: 500;

            @include respond-down(L) {
              padding: 18px 0 0 0;
            }

            .category-items {
              display: block;
              background: #fff;
              width: 280px;
              padding: 15px 0;
              box-shadow: 0 12px 43px rgba(0,0,0,0.16);
              border-radius: 10px;
              font-size: 18px;
              font-weight: 400;

              @include respond-down(L) {
                font-size: 16px;
                padding: 8px 0;
                width: 250px;
              }

              .item {
                color: $col_text_01;
                display: flex;
                justify-content: space-between;
                max-width: 400px;
                padding-top: 7px;
                padding-bottom: 7px;
                transition: color 0.15s;

                @include respond-down(L) {
                  margin: 8px 0;
                }

                &:hover {
                  color: $col_blue_01;
                }

                .count {
                  width: 54px;
                  height: 26px;
                  font-weight: 400;
                  color: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  border-radius: 30px;
                  background: $col_blue_01;
                }
              }
            }
          }

          .item {
            max-width: 200px;
            text-align: center;
            padding: 0 20px;
            transition: color 0.10s;

            display: flex;
            align-items: center;
            height: 33px;

            @include respond-down(L){
              height: 30px;
              max-width: 200px;
            }

            @include respond-down(M){
            }
          }

          &:hover {
            background: $col_link_03;

            .arrow-wrapper {
              svg {
                transform: rotate(0);

                .figure {
                  stroke: #fff;
                }
              }
            }

            .category-items-wrapper {
              visibility: visible;
              opacity: 1;
              transform: translateY(0px);
            }

            .item {
              color: #fff;
            }
          }
        }
      }

      .right {
        @include respond-down(M){
          display: none;
        }
      }
    }

    .opener {
      display: none;

      @include respond-down(M) {
        display: flex;
      }
    }
  }
}

.lang-select {
  display: flex;

  .locale {
    border-radius: 50px;
    width: 43px;
    height: 43px;
    margin: 7px;
    font-weight: 400;
    font-size: 20px;
    color: $col_text_02;
    transition: color 0.15s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-down(L){
      width: 30px;
      height: 30px;
      margin: 6px;
      font-size: 15px;
    }

    &.current {
      background: #EFEFEF;
      color: $col_text_01;
      cursor: default;
    }
    @include respond-up(M) {
      &:hover {
        color: $col_text_01;
      }
    }
  }
}
