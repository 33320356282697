.advantages-block {
  background-color: $col_nude_01;
  padding-bottom: 111px;
  position: relative;
  font-weight: 400;
  @include respond-property(padding-top, 95px,57px,63px,75px,50px);

  @include respond(XS){
    .head-block{
      padding: 0 7%;
    }
  }

  .container {
    position: relative;

    @include respond(XS){
      padding: 0;
    }

    .wrapper-arrow {
      position: absolute;
      bottom: 0;
      left: 23px;
      display: flex;
      justify-content: space-between;
      width: 75px;

      .slick-arrow {
        background: #ffffff;
      }
    }
  }

  @include respond-property(padding-bottom, 111px, 72px, 111px, 111px, 63px);

  .list-advantages{
    display: flex;
    flex-wrap: wrap;

    @include respond(XS){
      flex-wrap: nowrap;
      height: 210px;
      position: relative;
      padding-top: 20px;

      .slick-slide{
        padding-top: 10px;
      }

      .slick-list{
        padding-left: 0;

        &:after{
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 129px;
          background: linear-gradient(91deg, rgba(249,249,249,0.15) 0%, rgba(249,249,249,0.46) 30%, rgba(249,249,249,0.7) 53%, rgba(249,249,249,0.7) 100%); /* w3c */
          z-index: 20;
          width: 14%;
        }

        .slick-track{
          margin-left: 23px;
        }
      }


    }

    .item-advantages{
      position: relative;

      @include respond-property(padding-top, 58px, 40px, 33px, 37px, 38px);//ok

      .line{
        background-color:  $col_grey_03;
        height: 1px;
        position: absolute;
        left: 60px;
        top: 0;

        @include respond-property(width, 78%,78%,87%,79%,84%);

        &:after{
          content: '';
          background-color: $col_blue_01;
          position: absolute;
          left: 0;
          width: 5.6px;
          height: 5.6px;
          display: block;
          border-radius: 1000px;
          transform: translateY(-50%);

          @include respond-property(width, 6px, 6px, 5px, 5px, 5px);
          @include respond-property(height, 6px, 6px, 5px, 5px, 5px);
        }

        &:before{
          right: 0;
          content: '';
          background-color: $col_blue_01;
          position: absolute;
          width: 5.6px;
          height: 5.6px;
          display: block;
          border-radius: 1000px;
          transform: translateY(-50%);

          @include respond-property(width, 6px, 6px, 5px, 5px, 5px);
          @include respond-property(height, 6px, 6px, 5px, 5px, 5px);
        }

        @include respond-property(left, 60px, 42px,42px,42px,30px);



      }

      .num{
        font-weight: 300;
        letter-spacing: 0.1em;
        position: absolute;
        line-height: 1;
        @include respond-property(font-size, 44px, 32px, 32px, 32px, 24px);
        @include respond-property(top, -22px, -17px, -17px, -17px, -12px);
      }

      .title{
        display: block;
        @include respond-property(font-size, 22px, 16px, 16px, 18px, 16px);//ok
        @include respond-property(margin-bottom, 30px, 18px, 18px, 17px, 12px);//ok
      }

      .text{
        line-height: 1.5;
        display: block;
        @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);//ok
        @include respond-property(max-width, 300px,222px,216px,216px,216px);
        //@include respond-property(padding-right, 90px, 51px, 14px, 25px, 14px);//ok

        @include respond-up(XL){
          color: $col_text_02;
        }

        @include respond-down(XS){
          padding-right: 25px;
        }

      }


      &.first{
        @include respond-property(flex,0 0 26%, 0 0 26%, 0 0 59%, 0 0 55%, 0 0 217px);
        @include respond-property(order,1,1,1,1,1);

        @include respond(M) {
          padding-bottom: 55px;
        }
        @include respond(S) {
          padding-bottom: 85px;
        }

      }

      &.second{

        .line-vertical{
          display: none;
        }

        @include respond-property(flex,0 0 27%, 0 0 27%, 0 0 41%, 0 0 45%, 0 0 217px);
        @include respond-property(order,2,2,2,2,2);

        @include respond(M){
          padding-bottom: 55px;

          .line{
            left: 14%;
            width: 86%;

            &:before{
              display: none;


            }
          }

          .line-vertical{
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 1px;
            background-color: $col_grey_03;
          }
        }

        @include respond(S){
          padding-bottom: 85px;
          .line{
            left: 19%;
            width: 81%;

            &:before{
              display: none;


            }
          }

          .line-vertical{
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 1px;
            background-color: $col_grey_03;
          }
        }

      }

      &.third{
        @include respond-property(flex,0 0 27%, 0 0 27%, 0 0 41%, 0 0 45%, 0 0 217px);
        @include respond-property(order,3,3,4,4,3);

        @include respond(M) {

          .line {
            left: 14%;
            width: 86%;

            &:before {
              display: none;

            }
          }
        }

        @include respond(S){
          .line{
            left: 19%;
            width: 81%;

            &:before{
              display: none;
            }
          }
        }
      }

      &.fourth{
        @include respond-property(flex,0 0 20%, 0 0 20%, 0 0 59%, 0 0 55%, 0 0 217px);
        @include respond-property(order,4,4,3,3,4);

        .text{
          padding-right: 0;
        }
      }
    }

    .slick-arrow {
      z-index: 20;
      bottom: 0;
      position: absolute;
      text-indent: -9999px;
      overflow: hidden;
      display: block;
      transition: background-color 0.2s, background-image 0.2s;
      background-color: transparent;
      border: 1px solid $col_grey_01;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include respond-property(width, 56px, 41px,41px,47px, 35px);
      @include respond-property(height, 47px, 34px,35px,40px, 30px);

      background: {
        repeat: no-repeat;
        position: center;
        size: 5px 10px;
      };


      &.slick-prev{
        left: 20px;
        border-radius: 15px 0 0 15px;

        background-image: url("/static/images/image-icons/arrow-black-left.png");

        &.active{
          background-image: url("/static/images/image-icons/arrow-white-left.png");
        }

      }
      &.slick-next{
        left: 60px;
        border-radius: 0px 15px 15px 0;

        background-image: url("/static/images/image-icons/arrow-black-right.png");

        &.active{
          background-image: url("/static/images/image-icons/arrow-white-right.png");
        }
      }


      &.active{
        border-color: $col_blue_01;
        background-color: $col_blue_01;

      }
    }
  }

  .progress-bar{
    display: flex;
    align-items: center;
    width: 134px;
    position: absolute;
    bottom: 57px;
    right: 0;


    .start, .end{
      font-size: 27px;
      letter-spacing: 0.05em;
      transform: scale(1);
      transition: transform 0.4s;

      &.opacity{
        transform: scale(0.8);
      }

    }
    .progress-line{
      height: 1px;
      margin: 0 5px 0 5px;
      position: relative;
      flex: 0 0 55px;
      background: rgba(10, 31, 37, 0.11);


      .full{
        margin: 0;
        position: absolute;
        left: 0;
        width: 0;
        height: 100%;
        background: $col_blue_01;
      }


    }
    .end{

    }
  }
}