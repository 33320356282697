// background: #ffc;

.part-footer {
  //background: transparent;
  padding: 2.8% 0 2% 0;
  overflow: hidden;

  @include respond-down(L){
    padding: 5% 0 3.5% 0;
  }
  @include respond-down(M){
    padding: 6% 0 3.5% 0;
  }
  @include respond-down(S){
    padding: 10% 0 5% 0;
  }
  @include respond(XS){
    padding: 8% 0 8% 0;
  }

  .container-content {
    position: relative;
  }

  .separated {
    display: flex;

    @include respond(XS){
      flex-wrap: wrap;
    }

    .lined {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 1px;
        background: rgba(0,0,0,0.2);
        width: 0;
        transition: width 0.45s ease-in-out;
        @include respond-property(top, 30px, 20px, 20px, 20px, 20px);
      }

      &.animate {
        &:after {
          width: 100%;
        }
      }
    }

    .logo {
      flex: 0 0 192px;

      @include respond-down(L){
        flex-basis: 137px;
      }
      @include respond-down(XS){
        flex-basis: 98px;
      }

      .svg {
        display: block;
        margin-bottom: 22px;

        @include respond-down(L){
          margin-bottom: 18px;
        }

        .figure {
          fill: #747474;
          transition: fill 0.25s ease-in-out;
        }
        .circle {
          fill: #747474;
          transition: fill 1s ease-in-out;

          &.flash {
            fill: $col_blue_01;
            transition: fill 0.10s ease-in-out;
          }
        }

        @include respond-up(M) {
          &:hover {
            .circle {
              fill: #eee;
              &.flash {
                fill: $col_blue_01;
                transition: fill 0.10s ease-in-out;
              }
            }
            .figure {
              fill: $col_text_01;
              transition: fill 0.25s ease-in-out;
            }
          }
        }
      }

      .helper {
        font-size: 19px;
        font-weight: 300;
        color: $col_text_02; // #848F92;

        @include respond-down(L){
          font-size: 14px;
        }
        @include respond(XS){
          display: none;
        }
      }

    }

    .spacer {
      flex-grow: 0;
      flex-shrink: 0;
      @include respond-property(margin, 0 20px, 0 15px, 0 10px, 0 10px, 0);
      @include respond-property(width, 100px, 60px, 46px, 46px, 0);
    }

    .products-text {
      flex: 0 0 180px;
      @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);
      @include respond-property(padding-top, 18px, 10px, 10px, 10px, 10px);

      @include respond-down(L){
        flex-basis: 140px;
      }
      @include respond-down(M){
        display: none;
      }

      .title {
        color: #C9C9C9; // #0A1F25;
        font-weight: 500;
        text-transform: uppercase;
        @include respond-property(margin-bottom, 36px, 27px, 27px, 25px, 18px);
      }

      &:after {
        width: auto;
        max-width: 0;
        right: 0;
        transition: max-width 0.45s ease-in-out;
        @include respond-property(left, 115px, 78px, 68px, 75px, 75px);

        @include respond(XS){
          right: -50px;
        }
      }

      &.animate {
        &:after {
          max-width: 100%;
          width: auto;
        }
      }
    }

    .company-products {
      flex: 1 1 90%;
      @include respond-property(font-size, 19px, 14px, 14px, 16px, 14px);
      @include respond-property(margin-bottom, 90px, 60px, 40px, 20px, 5px);
      font-weight: 400;
      display: flex;

      @include respond-down(S){
        flex-wrap: wrap;
      }
      @include respond(XS){
        flex: 0 0 100%;
      }

      .col-category {
        flex: 0 0 26%;
        padding: 17px 0 0 25px;

        @include respond-down(L){
          flex: 0 0 32%;
          padding: 10px 0 0 16px;
        }
        @include respond-down(M){
          padding: 10px 10px 0 0;
        }
        @include respond-down(S){
          flex: 1 1 100%;

          &.make-full {
            .head {
              position: relative;

              &:after {
                content: '';
                width: 192px;
                left: -203px;
                position: absolute;
                top: 10px;
                height: 1px;
                background: rgba(0,0,0,0.2);
              }
            }
          }
        }
        @include respond-down(XS){
          &.make-full {
            .head:after {
              display: none;
            }
          }
        }

        &:last-child {
          flex: 1 1 auto;
        }

        .head {
          display: flex;
          align-items: center;
          margin-bottom: 25px;

          @include respond-down(S){
            margin-bottom: 10px;
          }
          @include respond-down(XS){
            margin-bottom: 5px;
            margin-right: -300px;
          }

          .title {
            flex: 0 0 auto;
            color: #0A1F25;
            cursor: default !important;
            margin-right: 25px;
            
            &.link {
              cursor: pointer !important;
              transition: color 0.15s linear;
              
              &:hover {
                color: #0073FF;
              }
            }

            @include respond-down(M){
              margin-right: 10px;
            }
          }

          .line {
            flex: 1 1 auto;
            height: 1px;
            display: block;
            max-width: 0;
            background: rgba(0, 0, 0, 0.2);

            @include respond-down(L){
              margin-top: 3px;
            }
          }

          &.animate {
            .line {
              max-width: 100%;
            }
          }
        }

        .items {
          display: flex;
          position: relative;
          flex-wrap: wrap;
          @include respond-property(max-width, 360px, 270px, 270px, 160px, 100%);

          @include respond-down(L){
            padding-right: 30px;
          }
          @include respond-down(S){
            padding-right: 0px;
            margin-bottom: 18px;
            justify-content: space-between;
          }
          @include respond-down(XS){
            margin-bottom: 10px;
            justify-content: flex-start;
          }

          .item {
            @include respond-property(min-width, 97px, 72px, 90px, 50%, 0);
            @include respond-property(margin-bottom, 15px, 10px, 10px, 8px, 10px);
            display: inline-block;
            color: rgba(10,31,37,0.5);
            transition: color 0.15s linear;
            text-shadow: 0 0 5px #fff;

            @include respond-down(XS){
              padding-right: 15px;
            }

            @include respond-up(M) {
              &:hover {
                color: rgba(10,31,37,1);
              }
            }
          }
        }
      }
    }
  }

  .lang-select {
    margin-left: 60px;

    @include respond-down(L){
      margin-left: 50px;
    }
    @include respond-down(S){
      margin: 0 -6px;
      position: absolute;
      left: 0;
      top: 96px;
    }
    @include respond(XS){
      left: auto;
      top: -7px;
      right: 0;
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(XS){
      flex-wrap: wrap;
    }

    .copy {
      @include respond-property(font-size, 19px, 14px, 14px, 14px, 12px);
      color: #C9C9C9;

      @include respond(XS){
        flex: 0 0 100%;
        opacity: 0.7;
        margin-bottom: 10px;
      }
    }

    .langs {
      display: flex;
      align-items: center;

      @include respond(XS){
        flex: 0 0 100%;
        opacity: 0.7;
      }

      .obercon-team {
        svg {
          width: 280px;

          @include respond-down(L){
            width: 240px;
          }

          .figure {
            fill: #848f92;
            transition: fill 0.25s ease;

            @include respond-down(M){
              fill: #848F92;
            }
          }
        }

        @include respond-up(M) {
          &:hover {
            svg {
              .figure {
                fill: rgba(10,31,37,1);
              }
            }
          }
        }
      }
    }
  }
}
