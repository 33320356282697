.about{
  padding-top: 72px;
  margin-bottom: 30px;

  .content-block{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .wrapper-text{
      display: block;
      @include respond-property(flex, 0 0 700px,0 0 500px,0 0 296px, 0 0 100%, 0 0 100%);
      @include respond(S){
        margin-bottom: 74px;

      }

      @include respond(XS){
        margin-bottom: 40px;
      }

      .title{
        display: block;
        @include respond-property(font-size, 44px,32px,27px,27px,20px);
        @include respond-property(margin-bottom, 56px,50px,38px,30px,18px);
        font-weight: 300;
        line-height: 1.1;

        @include respond-down(S){
          text-align: center;
        }
      }

      .text{
        display: block;
        font-weight: 400;
        color: $col_text_02;
        @include respond-property(font-size, 19px,14px,14px,18px,14px);
        line-height: 1.53;

        p{
          @include respond-property(margin-bottom, 20px,10px,13px,13px,10px);

          &:last-child{
            margin-bottom: 0;
          }

          @include respond-down(S) {
            text-align: center;
          }
        }

        @include respond-down(L){
          color: $col_text_01;
        }
      }
    }

    .wrapper-contact{
      @include respond-property(flex, 0 0 320px, 0 0 274px, 0 0 250px, 0 0 100%, 0 0 100%);
      @include respond-property(margin-left, 177px,70px,50px, 0, 0);

      .logo{
        @include respond-property(margin-bottom, 56px,50px,25px,30px,45px);
        @include respond-property(height, 31px,27px,27px,27px,27px);
        display: flex;
        justify-content: flex-end;


          svg {
            width: 137px;

            @include respond(XS){
              width: 113px;
            }
          }

          .figure {
            fill: $col_text_01;
            transition: fill 0.25s ease-in-out;
          }

          .circle {
            fill: $col_blue_02;
            transition: fill 1s ease-in-out;

            &.black {
              fill: $col_text_01;
            }

            &.flash {
              fill: $col_blue_01;
              transition: fill 0.10s ease-in-out;
            }
          }




        @include respond(M){
          background-position: right 15px center;
        }

        @include respond-down(S){
          flex:  0 0 100%;
          background-position: left center;
        }
      }

      .contact{
        background:  $col_nude_01;
        @include respond-property(padding, 38px 0 31px 41px ,24px 0 27px 36px,36px 0 36px 36px,26px 35px 31px,27px 40px 34px);
        border-radius: 29px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include respond-down(S){
          flex-direction:row;
          align-items:flex-start;
          flex-wrap: wrap;
        }

        .mail, .linkedin{
          display: flex;

          .image{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1000px;
            @include respond-property(width, 53px, 47px, 42px, 42px, 42px);
            @include respond-property(height, 53px, 47px, 42px, 42px, 42px);
          }


          .wrapper-value{
            @include respond-property(font-size, 19px,16px,14px,14px,14px);
            @include respond-property(margin-left, 21px, 18px,16px,9px,9px);

            .name{
              font-weight: 400;
              display: block;
              color: $col_text_01;
              @include respond-property(margin-bottom, 5px, 5px,5px,4px,4px);
            }
            .value{
              font-weight: 400;
              display: inline-block;
              color: $col_blue_01;
              border-bottom: 1px solid transparent;
              transition: border 0.2s;

              &:hover{
                border-color: $col_blue_01;
              }
            }
          }

          @include respond(S){
            flex: 0 0 50%;
          }

          @include respond(XS){
            flex: 0 0 100%;
          }

        }

        .mail{
          margin-bottom: 20px;
          .image{
            background-color: $col_nude_02;
            svg{
              width: 31px;

              @include respond-down(M){
                width: 20px;
              }
            }
          }
        }

        .linkedin{
          .image{
            background-color: $col_blue_01;
            svg{
              width: 25px;

              @include respond-down(M){
                width: 15px;
              }
            }
          }
        }
      }

    }

  }


}